<template>
  <!--begin::Wrapper-->
  <div>
    <div>
      <div class="modal-dialog mw-1000px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header d-flex flex-column pb-1">
            <!--begin::Title-->
            <h2 v-if="languageVersion == 'english'">Take the Survey</h2>
            <h2 v-else-if="languageVersion == 'spanish'">Tomar la encuesta</h2>
            <p class="m-0" v-if="isEmailQueReady">
              {{ emailQue.recipientFirstName }} {{ emailQue.recipientLastName }}
            </p>
            <p class="m-0">{{ emailQue.recipientEmail }}</p>
            <!--end::Title-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body scroll-y">
            <!--begin::Stepper-->
            <div
              ref="createAccountRef"
              class="stepper stepper-links d-flex flex-column"
              id="kt_create_account_stepper"
            >
              <!--begin::Nav-->
              <div class="stepper-nav py-2">
                <!--begin::Step 1-->
                <div class="stepper-item current" data-kt-stepper-element="nav">
                  <h3
                    class="stepper-title"
                    v-if="isEmailQueReady && languageVersion === 'english'"
                  >
                    Natural Survey
                  </h3>
                  <h3
                    class="stepper-title"
                    v-if="isEmailQueReady && languageVersion === 'spanish'"
                  >
                    Encuesta natural
                  </h3>
                </div>
                <!--end::Step 1-->

                <!--begin::Step 2-->
                <div class="stepper-item" data-kt-stepper-element="nav">
                  <h3
                    class="stepper-title"
                    v-if="isEmailQueReady && languageVersion === 'english'"
                  >
                    Environmental Survey
                  </h3>
                  <h3
                    class="stepper-title"
                    v-else-if="isEmailQueReady && languageVersion === 'spanish'"
                  >
                    Encuesta de entorno
                  </h3>
                </div>
                <!--end::Step 2-->

                <!--begin::Step 3-->
                <div class="stepper-item" data-kt-stepper-element="nav">
                  <h3
                    class="stepper-title"
                    v-if="isEmailQueReady && languageVersion === 'english'"
                  >
                    Review
                  </h3>
                  <h3
                    class="stepper-title"
                    v-else-if="isEmailQueReady && languageVersion === 'spanish'"
                  >
                    Revisar
                  </h3>
                </div>
                <!--end::Step 3-->
              </div>
              <!--end::Nav-->

              <!--begin::Form-->
              <form
                class="mx-auto mw-600px w-100 py-5"
                novalidate="novalidate"
                id="kt_create_account_form"
                @submit="handleStep"
              >
                <!--begin::Step 1-->
                <div class="current" data-kt-stepper-element="content">
                  <!--begin::Wrapper-->
                  <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-10 pb-lg-6">
                      <!--begin::Title-->
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'english'"
                      >
                        Natural Survey
                      </h2>
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'spanish'"
                      >
                        Encuesta Natural
                      </h2>
                      <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Input group-->
                    <div class="col-md-12 fv-row">
                      <div class="row g-9 mb-8">
                        <!--begin::Col-->
                        <NaturalSurveyWordList
                          v-if="isEmailQueReady"
                          :surveyType="'naturalself'"
                          :languageVersion="languageVersion"
                          @list-change="onListChange"
                        ></NaturalSurveyWordList>
                        <!--end::Col-->
                      </div>
                    </div>
                    <!--end::Input group-->
                    <!--begin::Heading-->
                    <div class="d-flex flex-row-fluid flex-center">
                      <!--begin::Title-->
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'english'"
                      >
                        Natural Survey
                      </h2>
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'spanish'"
                      >
                        Encuesta Natural
                      </h2>
                      <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Step 1-->

                <!--begin::Step 2-->
                <div data-kt-stepper-element="content">
                  <!--begin::Wrapper-->
                  <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-10 pb-lg-6">
                      <!--begin::Title-->
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'english'"
                      >
                        Environmental Survey
                      </h2>
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'spanish'"
                      >
                        Encuesta Ambiental
                      </h2>
                      <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Input group-->
                    <div class="col-md-12 fv-row">
                      <div class="row g-9 mb-8">
                        <!--begin::Col-->
                        <EnvironmentalSurveyWordList
                          v-if="isEmailQueReady"
                          :surveyType="'environmentalself'"
                          :languageVersion="languageVersion"
                          @list-change="onListChange"
                        ></EnvironmentalSurveyWordList>
                        <!--end::Col-->
                      </div>
                    </div>
                    <!--end::Input group-->
                    <!--begin::Heading-->
                    <div class="d-flex flex-row-fluid flex-center">
                      <!--begin::Title-->
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'english'"
                      >
                        Environmental Survey
                      </h2>
                      <h2
                        class="fw-bolder text-dark"
                        v-if="isEmailQueReady && languageVersion === 'spanish'"
                      >
                        Encuesta Ambiental
                      </h2>
                      <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Step 2-->

                <!--begin::Step 3-->
                <div data-kt-stepper-element="content">
                  <!--begin::Wrapper-->
                  <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-4">
                      <!--begin::Title-->
                      <h2
                        v-if="languageVersion === 'english'"
                        class="fw-bolder text-dark"
                      >
                        {{ emailQue.recipientFirstName }}, please review your
                        Information!
                      </h2>
                      <h2
                        v-else-if="languageVersion === 'spanish'"
                        class="fw-bolder text-dark"
                      >
                        {{ emailQue.recipientFirstName }}, Revise su
                        información.
                      </h2>
                      <!--end::Title-->
                    </div>
                    <!--end::Heading-->

                    <!--begin::Body-->
                    <div class="mb-0">
                      <!--begin::Alert-->
                      <div
                        class="
                          notice
                          d-flex
                          flex-row
                          bg-light-warning
                          rounded
                          border-warning border border-dashed
                          p-6
                        "
                      >
                        <div class="d-flex flex-column flex-row-fluid">
                          <div>
                            <p v-if="languageVersion === 'english'">
                              If you have reviewed your information and are done
                              with the survey, please click "submit"
                            </p>
                            <p v-else-if="languageVersion === 'spanish'">
                              Si ha terminado la encuesta y ya ha revisado su
                              información, haga clic en "enviar".
                            </p>
                          </div>
                        </div>
                      </div>
                      <!--end::Alert-->
                    </div>
                    <!--end::Body-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Step 3-->

                <!--begin::Actions-->
                <div class="d-flex flex-stack pt-15">
                  <!--begin::Wrapper-->
                  <div class="me-2">
                    <button
                      type="button"
                      class="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                      @click="previousStep()"
                    >
                      <span class="svg-icon svg-icon-3 me-1">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr063.svg"
                        />
                      </span>
                      <span v-if="languageVersion === 'english'">Back</span>
                      <span v-else-if="languageVersion === 'spanish'"
                        >Atrás</span
                      >
                    </button>
                  </div>
                  <!--end::Wrapper-->

                  <!--begin::Wrapper-->
                  <div v-if="isEmailQueReady">
                    <button
                      type="submit"
                      ref="submitButton"
                      class="btn btn-lg btn-primary"
                      v-if="currentStepIndex === totalSteps - 1"
                      @click="formSubmit()"
                    >
                      <span class="indicator-label">
                        <span v-if="languageVersion === 'english'">Submit</span>
                        <span v-else-if="languageVersion === 'spanish'"
                          >Enviar
                        </span>
                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                          <inline-svg src="icons/duotune/arrows/arr064.svg" />
                        </span>
                      </span>
                      <span class="indicator-progress">
                        <span v-if="languageVersion === 'english'"
                          >Please wait...</span
                        >
                        <span v-else-if="languageVersion === 'spanish'"
                          >Por favor, espere...</span
                        >
                        <span
                          class="
                            spinner-border spinner-border-sm
                            align-middle
                            ms-2
                          "
                        ></span>
                      </span>
                    </button>

                    <button type="submit" class="btn btn-lg btn-primary" v-else>
                      <span v-if="languageVersion === 'english'">Continue</span>
                      <span v-else-if="languageVersion === 'spanish'"
                        >Continuar</span
                      >
                      <span class="svg-icon svg-icon-3 ms-1 me-0">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr064.svg"
                        />
                      </span>
                    </button>
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Actions-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Stepper-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
    </div>
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import NaturalSurveyWordList from "@/components/surveys/BehavioralSurveyWordList.vue";
import EnvironmentalSurveyWordList from "@/components/surveys/BehavioralSurveyWordList.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { BehavioralWordLineItem } from "../../core/types/BehavioralTypes";
import { EmailQueRequest } from "../../core/types/EmailTypes";

interface Step1 {
  currentAspNetUserId: string;
  respondentAspNetUserId: string;
  emailSurveyQueId: number;
  userExists: boolean;
  surveyEntryType: string;
  languageVersion: string;
  firstName: string;
  lastName: string;
  email: string;
  licensedDistributorId: number;
  clientId: number;
  folderId: number;
  personalFolderId: number;
}

interface Step2 {
  naturalAutonomyTotal: number;
  naturalSocialTotal: number;
  naturalPatienceTotal: number;
  naturalCertaintyTotal: number;
  naturalJudgementTotal: number;
  naturalDistractorTotal: number;
  naturalPsychicEnergyTotal: number;
  environmentalAutonomyTotal: number;
  environmentalSocialTotal: number;
  environmentalPatienceTotal: number;
  environmentalCertaintyTotal: number;
  environmentalJudgementTotal: number;
  environmentalDistractorTotal: number;
  environmentalPsychicEnergyTotal: number;
  naturalBehavioralWordLineItems: BehavioralWordLineItem[];
  environmentalBehavioralWordLineItems: BehavioralWordLineItem[];
}

interface KTCreateApp extends Step1, Step2 {}

export default defineComponent({
  name: "take-survey",
  props: ["query"],
  components: {
    NaturalSurveyWordList,
    EnvironmentalSurveyWordList,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const modalRef = ref<null | HTMLFormElement>(null);
    const emailQue = computed(() => {
      return store.getters.getQuedEmail;
    });
    const isEmailQueReady = ref(false);
    const emailQueRequest = ref<EmailQueRequest>({
      id: 0,
    });
    const languageVersion = ref("");
    const submitButton = ref<HTMLButtonElement | null>(null);

    const formData = ref<KTCreateApp>({
      currentAspNetUserId: "",
      respondentAspNetUserId: props.query.uid,
      emailSurveyQueId: props.query.queid,
      userExists: true,
      surveyEntryType: "",
      languageVersion: "",
      firstName: "",
      lastName: "",
      email: "",
      licensedDistributorId: 0,
      clientId: 0,
      folderId: 0,
      personalFolderId: 0,
      naturalAutonomyTotal: 0,
      naturalSocialTotal: 0,
      naturalPatienceTotal: 0,
      naturalCertaintyTotal: 0,
      naturalJudgementTotal: 0,
      naturalDistractorTotal: 0,
      naturalPsychicEnergyTotal: 0,
      environmentalAutonomyTotal: 0,
      environmentalSocialTotal: 0,
      environmentalPatienceTotal: 0,
      environmentalCertaintyTotal: 0,
      environmentalJudgementTotal: 0,
      environmentalDistractorTotal: 0,
      environmentalPsychicEnergyTotal: 0,
      naturalBehavioralWordLineItems: [],
      environmentalBehavioralWordLineItems: [],
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );

      emailQueRequest.value.id = props.query.queid;
      store
        .dispatch(Actions.GET_EMAIL_QUE, emailQueRequest.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    const createAppSchema = [Yup.object({})];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      //::Natural Traits
      formData.value.naturalAutonomyTotal =
        calculateNaturalAutonomyTraits.value;
      formData.value.naturalSocialTotal = calculateNaturalSocialTraits.value;
      formData.value.naturalPatienceTotal =
        calculateNaturalPatienceTraits.value;
      formData.value.naturalCertaintyTotal =
        calculateNaturalCertaintyTraits.value;
      formData.value.naturalJudgementTotal =
        calculateNaturalJudgementTraits.value;
      formData.value.naturalDistractorTotal = calculateNaturalDistractors.value;
      formData.value.naturalPsychicEnergyTotal =
        calculateNaturalPyschicEnergy.value;
      formData.value.naturalBehavioralWordLineItems =
        naturalSelfCheckedWords.value;
      //::Environmental Traits
      formData.value.environmentalAutonomyTotal =
        calculateEnvironmentalAutonomyTraits.value;
      formData.value.environmentalSocialTotal =
        calculateEnvironmentalSocialTraits.value;
      formData.value.environmentalPatienceTotal =
        calculateEnvironmentalPatienceTraits.value;
      formData.value.environmentalCertaintyTotal =
        calculateEnvironmentalCertaintyTraits.value;
      formData.value.environmentalJudgementTotal =
        calculateEnvironmentalJudgementTraits.value;
      formData.value.environmentalDistractorTotal =
        calculateEnvironmentalDistractors.value;
      formData.value.environmentalPsychicEnergyTotal =
        calculateEnvironmentalPyschicEnergy.value;
      formData.value.environmentalBehavioralWordLineItems =
        environmentalSelfCheckedWords.value;

      formData.value.surveyEntryType = "email";
      formData.value.languageVersion = languageVersion.value;
      formData.value.licensedDistributorId = 0;
      formData.value.clientId = emailQue.value.clientId;
      formData.value.folderId = emailQue.value.folderId;
      formData.value.personalFolderId = emailQue.value.personalFolderId;
      formData.value.firstName = emailQue.value.recipientFirstName;
      formData.value.lastName = emailQue.value.recipientLastName;
      formData.value.email = emailQue.value.recipientEmail;
      formData.value.currentAspNetUserId = emailQue.value.sentByAspNetUserId;

      store
        .dispatch(Actions.CREATE_BEHAVIORAL_SURVEY, formData.value)
        .then(() => {
          Swal.fire({
            text: "Success. Your survey has been submitted. (Éxito. Su encuesta ha sido enviada.)",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            router.push("/takesurveysuccess");
            //modalRef.value?.click();
            //window.location.reload();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again! (¡Inténtelo de nuevo!)",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    //::Begin Natural Self
    const naturalSelfCheckedWords = ref([] as BehavioralWordLineItem[]);
    const generateNaturalTraitString = computed(() => {
      var str = "";
      for (let i = 0; i < naturalSelfCheckedWords.value.length; i++) {
        str += naturalSelfCheckedWords.value[i].traits + ", ";
      }
      return str;
    });

    const calculateNaturalAutonomyTraits = computed(() => {
      return generateNaturalTraitString.value.split("Autonomy").length - 1;
    });
    const calculateNaturalSocialTraits = computed(() => {
      return generateNaturalTraitString.value.split("Social").length - 1;
    });
    const calculateNaturalPatienceTraits = computed(() => {
      return generateNaturalTraitString.value.split("Patience").length - 1;
    });
    const calculateNaturalCertaintyTraits = computed(() => {
      return generateNaturalTraitString.value.split("Certainty").length - 1;
    });
    const calculateNaturalJudgementTraits = computed(() => {
      return generateNaturalTraitString.value.split("Judgement").length - 1;
    });
    const calculateNaturalDistractors = computed(() => {
      return generateNaturalTraitString.value.split("Distractor").length - 1;
    });
    const calculateNaturalPyschicEnergy = computed(() => {
      return (
        calculateNaturalAutonomyTraits.value +
        calculateNaturalSocialTraits.value +
        calculateNaturalPatienceTraits.value +
        calculateNaturalCertaintyTraits.value +
        calculateNaturalDistractors.value
      );
    });
    //::End Natural Self

    //::Begin Environmental Self
    const environmentalSelfCheckedWords = ref([] as BehavioralWordLineItem[]);
    const generateEnvironmentalTraitString = computed(() => {
      var str = "";
      for (let i = 0; i < environmentalSelfCheckedWords.value.length; i++) {
        str += environmentalSelfCheckedWords.value[i].traits + ", ";
      }
      return str;
    });
    const calculateEnvironmentalAutonomyTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Autonomy").length - 1
      );
    });
    const calculateEnvironmentalSocialTraits = computed(() => {
      return generateEnvironmentalTraitString.value.split("Social").length - 1;
    });
    const calculateEnvironmentalPatienceTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Patience").length - 1
      );
    });
    const calculateEnvironmentalCertaintyTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Certainty").length - 1
      );
    });
    const calculateEnvironmentalJudgementTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Judgement").length - 1
      );
    });
    const calculateEnvironmentalDistractors = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Distractor").length - 1
      );
    });
    const calculateEnvironmentalPyschicEnergy = computed(() => {
      return (
        calculateEnvironmentalAutonomyTraits.value +
        calculateEnvironmentalSocialTraits.value +
        calculateEnvironmentalPatienceTraits.value +
        calculateEnvironmentalCertaintyTraits.value +
        calculateEnvironmentalDistractors.value
      );
    });
    //::End Environmental Self

    //::Begin Emit
    function onListChange(event) {
      if (event.surveyType === "naturalself") {
        naturalSelfCheckedWords.value = [];
        for (let i = 0; i < event.checkedWords.length; i++) {
          naturalSelfCheckedWords.value.unshift(event.checkedWords[i]);
        }
      } else if (event.surveyType === "environmentalself") {
        environmentalSelfCheckedWords.value = [];
        for (let i = 0; i < event.checkedWords.length; i++) {
          environmentalSelfCheckedWords.value.unshift(event.checkedWords[i]);
        }
      }
    }
    //::End Emit

    async function reset() {
      resetForm();
    }

    watch([emailQue], () => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          //icon: "question",
          imageUrl:
            "https://www.inclineworkplace.com/media/logos/Incline_Secondary_Logo.svg",
          imageWidth: 280,
          imageHeight: 66,
          imageAlt: "Custom image",
          showCancelButton: true,
          text: "Select Language",
          confirmButtonText: "English",
          cancelButtonText: "Espanol",
          cancelButtonColor: "#3085d6",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            languageVersion.value = "english";
            Swal.fire({
              title:
                "<strong><span style='font-size:20px'>Before you start.</span></strong>",
              imageUrl: "/media/logos/Incline_Secondary_Logo.svg",
              imageWidth: 350,
              imageAlt: "Incline Surveys",
              html:
                "<ol style='text-align: left;'>" +
                "<li style='padding: 4px 0px 4px 0px;'>Read the instructions at the top of each page.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Check as many or as few words as you like.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>There is NO time limit. Take as much or little time as you need.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>If you do not know what a word means just skip it.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Do not ask anyone for help - this is about you and your perception.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Answer as honestly as you can, there is not a right or wrong answer.</li>" +
                "</ol>",
              width: 575,
              showCloseButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Got It!',
              confirmButtonAriaLabel: "Got it, great!",
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            languageVersion.value = "spanish";
            Swal.fire({
              title:
                "<strong><span style='font-size:20px'>Antes de comenzar</span></strong>",
              imageUrl: "/media/logos/Incline_Secondary_Logo.svg",
              imageWidth: 350,
              imageAlt: "Incline Surveys",
              html:
                "<ol style='text-align: left;'>" +
                "<li style='padding: 4px 0px 4px 0px;'>Lea las instrucciones en el encabezado de cada página.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Marque todas las palabras que desee.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>NO hay un límite de tiempo. Tómese todo el tiempo que necesite.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Si no sabe el significado de alguna palabra, omítala.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>No pida ayuda a nadie, esto se trata de usted y su percepción.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Responda de la forma más honesta que pueda, no existe una respuesta correcta o incorrecta.</li>" +
                "</ol>",
              width: 575,
              showCloseButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> ¡Entendido!',
              confirmButtonAriaLabel: "Entendido, ¡excelente!",
            });
          }
        });
      isEmailQueReady.value = true;
      if (emailQue.value.completed) {
        router.push("/takesurveysuccess");
      }
    });

    return {
      router,
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      modalRef,
      languageVersion,
      //Natural Self
      naturalSelfCheckedWords,
      generateNaturalTraitString,
      calculateNaturalAutonomyTraits,
      calculateNaturalSocialTraits,
      calculateNaturalPatienceTraits,
      calculateNaturalCertaintyTraits,
      calculateNaturalJudgementTraits,
      calculateNaturalDistractors,
      calculateNaturalPyschicEnergy,
      //Environmental Self
      environmentalSelfCheckedWords,
      generateEnvironmentalTraitString,
      calculateEnvironmentalAutonomyTraits,
      calculateEnvironmentalSocialTraits,
      calculateEnvironmentalPatienceTraits,
      calculateEnvironmentalCertaintyTraits,
      calculateEnvironmentalJudgementTraits,
      calculateEnvironmentalDistractors,
      calculateEnvironmentalPyschicEnergy,
      //end
      onListChange,
      emailQue,
      isEmailQueReady,
      emailQueRequest,
      formSubmit,
      currentStepIndex,
      formData,
      submitButton,
      reset,
    };
  },
});
</script>
