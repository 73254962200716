
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import NaturalSurveyWordList from "@/components/surveys/BehavioralSurveyWordList.vue";
import EnvironmentalSurveyWordList from "@/components/surveys/BehavioralSurveyWordList.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { BehavioralWordLineItem } from "../../core/types/BehavioralTypes";
import { EmailQueRequest } from "../../core/types/EmailTypes";

interface Step1 {
  currentAspNetUserId: string;
  respondentAspNetUserId: string;
  emailSurveyQueId: number;
  userExists: boolean;
  surveyEntryType: string;
  languageVersion: string;
  firstName: string;
  lastName: string;
  email: string;
  licensedDistributorId: number;
  clientId: number;
  folderId: number;
  personalFolderId: number;
}

interface Step2 {
  naturalAutonomyTotal: number;
  naturalSocialTotal: number;
  naturalPatienceTotal: number;
  naturalCertaintyTotal: number;
  naturalJudgementTotal: number;
  naturalDistractorTotal: number;
  naturalPsychicEnergyTotal: number;
  environmentalAutonomyTotal: number;
  environmentalSocialTotal: number;
  environmentalPatienceTotal: number;
  environmentalCertaintyTotal: number;
  environmentalJudgementTotal: number;
  environmentalDistractorTotal: number;
  environmentalPsychicEnergyTotal: number;
  naturalBehavioralWordLineItems: BehavioralWordLineItem[];
  environmentalBehavioralWordLineItems: BehavioralWordLineItem[];
}

interface KTCreateApp extends Step1, Step2 {}

export default defineComponent({
  name: "take-survey",
  props: ["query"],
  components: {
    NaturalSurveyWordList,
    EnvironmentalSurveyWordList,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const modalRef = ref<null | HTMLFormElement>(null);
    const emailQue = computed(() => {
      return store.getters.getQuedEmail;
    });
    const isEmailQueReady = ref(false);
    const emailQueRequest = ref<EmailQueRequest>({
      id: 0,
    });
    const languageVersion = ref("");
    const submitButton = ref<HTMLButtonElement | null>(null);

    const formData = ref<KTCreateApp>({
      currentAspNetUserId: "",
      respondentAspNetUserId: props.query.uid,
      emailSurveyQueId: props.query.queid,
      userExists: true,
      surveyEntryType: "",
      languageVersion: "",
      firstName: "",
      lastName: "",
      email: "",
      licensedDistributorId: 0,
      clientId: 0,
      folderId: 0,
      personalFolderId: 0,
      naturalAutonomyTotal: 0,
      naturalSocialTotal: 0,
      naturalPatienceTotal: 0,
      naturalCertaintyTotal: 0,
      naturalJudgementTotal: 0,
      naturalDistractorTotal: 0,
      naturalPsychicEnergyTotal: 0,
      environmentalAutonomyTotal: 0,
      environmentalSocialTotal: 0,
      environmentalPatienceTotal: 0,
      environmentalCertaintyTotal: 0,
      environmentalJudgementTotal: 0,
      environmentalDistractorTotal: 0,
      environmentalPsychicEnergyTotal: 0,
      naturalBehavioralWordLineItems: [],
      environmentalBehavioralWordLineItems: [],
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );

      emailQueRequest.value.id = props.query.queid;
      store
        .dispatch(Actions.GET_EMAIL_QUE, emailQueRequest.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    const createAppSchema = [Yup.object({})];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      //::Natural Traits
      formData.value.naturalAutonomyTotal =
        calculateNaturalAutonomyTraits.value;
      formData.value.naturalSocialTotal = calculateNaturalSocialTraits.value;
      formData.value.naturalPatienceTotal =
        calculateNaturalPatienceTraits.value;
      formData.value.naturalCertaintyTotal =
        calculateNaturalCertaintyTraits.value;
      formData.value.naturalJudgementTotal =
        calculateNaturalJudgementTraits.value;
      formData.value.naturalDistractorTotal = calculateNaturalDistractors.value;
      formData.value.naturalPsychicEnergyTotal =
        calculateNaturalPyschicEnergy.value;
      formData.value.naturalBehavioralWordLineItems =
        naturalSelfCheckedWords.value;
      //::Environmental Traits
      formData.value.environmentalAutonomyTotal =
        calculateEnvironmentalAutonomyTraits.value;
      formData.value.environmentalSocialTotal =
        calculateEnvironmentalSocialTraits.value;
      formData.value.environmentalPatienceTotal =
        calculateEnvironmentalPatienceTraits.value;
      formData.value.environmentalCertaintyTotal =
        calculateEnvironmentalCertaintyTraits.value;
      formData.value.environmentalJudgementTotal =
        calculateEnvironmentalJudgementTraits.value;
      formData.value.environmentalDistractorTotal =
        calculateEnvironmentalDistractors.value;
      formData.value.environmentalPsychicEnergyTotal =
        calculateEnvironmentalPyschicEnergy.value;
      formData.value.environmentalBehavioralWordLineItems =
        environmentalSelfCheckedWords.value;

      formData.value.surveyEntryType = "email";
      formData.value.languageVersion = languageVersion.value;
      formData.value.licensedDistributorId = 0;
      formData.value.clientId = emailQue.value.clientId;
      formData.value.folderId = emailQue.value.folderId;
      formData.value.personalFolderId = emailQue.value.personalFolderId;
      formData.value.firstName = emailQue.value.recipientFirstName;
      formData.value.lastName = emailQue.value.recipientLastName;
      formData.value.email = emailQue.value.recipientEmail;
      formData.value.currentAspNetUserId = emailQue.value.sentByAspNetUserId;

      store
        .dispatch(Actions.CREATE_BEHAVIORAL_SURVEY, formData.value)
        .then(() => {
          Swal.fire({
            text: "Success. Your survey has been submitted. (Éxito. Su encuesta ha sido enviada.)",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            router.push("/takesurveysuccess");
            //modalRef.value?.click();
            //window.location.reload();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again! (¡Inténtelo de nuevo!)",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    //::Begin Natural Self
    const naturalSelfCheckedWords = ref([] as BehavioralWordLineItem[]);
    const generateNaturalTraitString = computed(() => {
      var str = "";
      for (let i = 0; i < naturalSelfCheckedWords.value.length; i++) {
        str += naturalSelfCheckedWords.value[i].traits + ", ";
      }
      return str;
    });

    const calculateNaturalAutonomyTraits = computed(() => {
      return generateNaturalTraitString.value.split("Autonomy").length - 1;
    });
    const calculateNaturalSocialTraits = computed(() => {
      return generateNaturalTraitString.value.split("Social").length - 1;
    });
    const calculateNaturalPatienceTraits = computed(() => {
      return generateNaturalTraitString.value.split("Patience").length - 1;
    });
    const calculateNaturalCertaintyTraits = computed(() => {
      return generateNaturalTraitString.value.split("Certainty").length - 1;
    });
    const calculateNaturalJudgementTraits = computed(() => {
      return generateNaturalTraitString.value.split("Judgement").length - 1;
    });
    const calculateNaturalDistractors = computed(() => {
      return generateNaturalTraitString.value.split("Distractor").length - 1;
    });
    const calculateNaturalPyschicEnergy = computed(() => {
      return (
        calculateNaturalAutonomyTraits.value +
        calculateNaturalSocialTraits.value +
        calculateNaturalPatienceTraits.value +
        calculateNaturalCertaintyTraits.value +
        calculateNaturalDistractors.value
      );
    });
    //::End Natural Self

    //::Begin Environmental Self
    const environmentalSelfCheckedWords = ref([] as BehavioralWordLineItem[]);
    const generateEnvironmentalTraitString = computed(() => {
      var str = "";
      for (let i = 0; i < environmentalSelfCheckedWords.value.length; i++) {
        str += environmentalSelfCheckedWords.value[i].traits + ", ";
      }
      return str;
    });
    const calculateEnvironmentalAutonomyTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Autonomy").length - 1
      );
    });
    const calculateEnvironmentalSocialTraits = computed(() => {
      return generateEnvironmentalTraitString.value.split("Social").length - 1;
    });
    const calculateEnvironmentalPatienceTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Patience").length - 1
      );
    });
    const calculateEnvironmentalCertaintyTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Certainty").length - 1
      );
    });
    const calculateEnvironmentalJudgementTraits = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Judgement").length - 1
      );
    });
    const calculateEnvironmentalDistractors = computed(() => {
      return (
        generateEnvironmentalTraitString.value.split("Distractor").length - 1
      );
    });
    const calculateEnvironmentalPyschicEnergy = computed(() => {
      return (
        calculateEnvironmentalAutonomyTraits.value +
        calculateEnvironmentalSocialTraits.value +
        calculateEnvironmentalPatienceTraits.value +
        calculateEnvironmentalCertaintyTraits.value +
        calculateEnvironmentalDistractors.value
      );
    });
    //::End Environmental Self

    //::Begin Emit
    function onListChange(event) {
      if (event.surveyType === "naturalself") {
        naturalSelfCheckedWords.value = [];
        for (let i = 0; i < event.checkedWords.length; i++) {
          naturalSelfCheckedWords.value.unshift(event.checkedWords[i]);
        }
      } else if (event.surveyType === "environmentalself") {
        environmentalSelfCheckedWords.value = [];
        for (let i = 0; i < event.checkedWords.length; i++) {
          environmentalSelfCheckedWords.value.unshift(event.checkedWords[i]);
        }
      }
    }
    //::End Emit

    async function reset() {
      resetForm();
    }

    watch([emailQue], () => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          //icon: "question",
          imageUrl:
            "https://www.inclineworkplace.com/media/logos/Incline_Secondary_Logo.svg",
          imageWidth: 280,
          imageHeight: 66,
          imageAlt: "Custom image",
          showCancelButton: true,
          text: "Select Language",
          confirmButtonText: "English",
          cancelButtonText: "Espanol",
          cancelButtonColor: "#3085d6",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            languageVersion.value = "english";
            Swal.fire({
              title:
                "<strong><span style='font-size:20px'>Before you start.</span></strong>",
              imageUrl: "/media/logos/Incline_Secondary_Logo.svg",
              imageWidth: 350,
              imageAlt: "Incline Surveys",
              html:
                "<ol style='text-align: left;'>" +
                "<li style='padding: 4px 0px 4px 0px;'>Read the instructions at the top of each page.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Check as many or as few words as you like.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>There is NO time limit. Take as much or little time as you need.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>If you do not know what a word means just skip it.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Do not ask anyone for help - this is about you and your perception.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Answer as honestly as you can, there is not a right or wrong answer.</li>" +
                "</ol>",
              width: 575,
              showCloseButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Got It!',
              confirmButtonAriaLabel: "Got it, great!",
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            languageVersion.value = "spanish";
            Swal.fire({
              title:
                "<strong><span style='font-size:20px'>Antes de comenzar</span></strong>",
              imageUrl: "/media/logos/Incline_Secondary_Logo.svg",
              imageWidth: 350,
              imageAlt: "Incline Surveys",
              html:
                "<ol style='text-align: left;'>" +
                "<li style='padding: 4px 0px 4px 0px;'>Lea las instrucciones en el encabezado de cada página.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Marque todas las palabras que desee.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>NO hay un límite de tiempo. Tómese todo el tiempo que necesite.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Si no sabe el significado de alguna palabra, omítala.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>No pida ayuda a nadie, esto se trata de usted y su percepción.</li>" +
                "<li style='padding: 4px 0px 4px 0px;'>Responda de la forma más honesta que pueda, no existe una respuesta correcta o incorrecta.</li>" +
                "</ol>",
              width: 575,
              showCloseButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> ¡Entendido!',
              confirmButtonAriaLabel: "Entendido, ¡excelente!",
            });
          }
        });
      isEmailQueReady.value = true;
      if (emailQue.value.completed) {
        router.push("/takesurveysuccess");
      }
    });

    return {
      router,
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      modalRef,
      languageVersion,
      //Natural Self
      naturalSelfCheckedWords,
      generateNaturalTraitString,
      calculateNaturalAutonomyTraits,
      calculateNaturalSocialTraits,
      calculateNaturalPatienceTraits,
      calculateNaturalCertaintyTraits,
      calculateNaturalJudgementTraits,
      calculateNaturalDistractors,
      calculateNaturalPyschicEnergy,
      //Environmental Self
      environmentalSelfCheckedWords,
      generateEnvironmentalTraitString,
      calculateEnvironmentalAutonomyTraits,
      calculateEnvironmentalSocialTraits,
      calculateEnvironmentalPatienceTraits,
      calculateEnvironmentalCertaintyTraits,
      calculateEnvironmentalJudgementTraits,
      calculateEnvironmentalDistractors,
      calculateEnvironmentalPyschicEnergy,
      //end
      onListChange,
      emailQue,
      isEmailQueReady,
      emailQueRequest,
      formSubmit,
      currentStepIndex,
      formData,
      submitButton,
      reset,
    };
  },
});
