<template>
  <div class="mt-2">
    <p
      style="
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
        font-style: italic;
      "
    >
      <strong v-if="languageVersion == 'english'">Instructions:</strong>
      <strong v-else-if="languageVersion == 'spanish'">Instrucciones:</strong>
    </p>
    <p
      v-if="surveyType == 'naturalself' && languageVersion === 'english'"
      style="font-size: 16px; margin-bottom: 20px; font-style: italic"
    >
      Take as much time as you need and check the boxes next to words that
      describe who you really are.
    </p>
    <p
      v-if="surveyType == 'environmentalself' && languageVersion === 'english'"
      style="font-size: 16px; margin-bottom: 20px; font-style: italic"
    >
      Take as much time as you need and check the boxes next to words that
      describe how you feel you have to behave to succeed at your job.
    </p>
    <p
      v-else-if="surveyType == 'naturalself' && languageVersion === 'spanish'"
      style="font-size: 16px; margin-bottom: 20px; font-style: italic"
    >
      Tome todo el tiempo que necesite y marque las casillas junto a las
      palabras que describen quién es usted realmente.
    </p>
    <p
      v-else-if="
        surveyType == 'environmentalself' && languageVersion === 'spanish'
      "
      style="font-size: 16px; margin-bottom: 20px; font-style: italic"
    >
      Tome todo el tiempo que necesite y marque las casillas junto a las
      palabras que describen cómo siente que debe comportarse para tener éxito
      en su trabajo.
    </p>
    <div v-if="isDataReady">
      <ul
        style="
          -webkit-column-count: 3;
          -moz-column-count: 3;
          column-count: 3;
          list-style-type: none;
          padding-left: 0px;
        "
      >
        <li
          v-for="(word, index) in words"
          :key="word.behavioralWordId"
          :id="'word_' + index"
        >
          <div class="form-check form-check-custom form-check-solid mx-3 mb-5">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="wordListData.checkedWords"
              :value="word"
              :id="'flexCheckDefault' + index"
            />
            <label
              v-if="languageVersion === 'english'"
              class="form-check-label"
              :for="'flexCheckDefault' + index"
              >{{ word.word }}</label
            >
            <label
              v-else-if="languageVersion === 'spanish'"
              class="form-check-label"
              :for="'flexCheckDefault' + index"
              >{{ word.spanishWord }}</label
            >
          </div>
        </li>
      </ul>
    </div>

    <div v-else>
      <TableSkeleton></TableSkeleton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { BehavioralWordLineItem } from "../../core/types/BehavioralTypes";

interface WordListData {
  surveyType: string;
  languageVersion: string;
  checkedWords: BehavioralWordLineItem[];
}

export default defineComponent({
  name: "behavioral-survey-word-list",
  props: ["surveyType", "languageVersion"],
  components: { TableSkeleton },
  emits: ["list-change"],
  setup(props, { emit }) {
    const store = useStore();
    const isDataReady = ref(false);
    const words = computed(() => {
      if (props.surveyType === "naturalself") {
        return store.getters.getWordListItems;
      } else {
        return store.getters.getEnvironmentWordListItems;
      }
    });
    //const checkedWords = ref([] as BehavioralWordLineItem[]);
    const wordListData = ref({
      surveyType: props.surveyType,
      languageVersion: props.languageVersion,
      checkedWords: [],
    } as WordListData);
    store
      .dispatch(Actions.GET_BEHAVIORAL_WORDLIST_ITEMS, props.surveyType)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the list of words.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const listChange = () => {
      emit("list-change", wordListData.value);
    };

    watch(wordListData.value, () => {
      listChange();
    });

    watch(words, () => {
      isDataReady.value = true;
    });

    return { isDataReady, words, wordListData, listChange };
  },
});
</script>
