
import { defineComponent, computed, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { BehavioralWordLineItem } from "../../core/types/BehavioralTypes";

interface WordListData {
  surveyType: string;
  languageVersion: string;
  checkedWords: BehavioralWordLineItem[];
}

export default defineComponent({
  name: "behavioral-survey-word-list",
  props: ["surveyType", "languageVersion"],
  components: { TableSkeleton },
  emits: ["list-change"],
  setup(props, { emit }) {
    const store = useStore();
    const isDataReady = ref(false);
    const words = computed(() => {
      if (props.surveyType === "naturalself") {
        return store.getters.getWordListItems;
      } else {
        return store.getters.getEnvironmentWordListItems;
      }
    });
    //const checkedWords = ref([] as BehavioralWordLineItem[]);
    const wordListData = ref({
      surveyType: props.surveyType,
      languageVersion: props.languageVersion,
      checkedWords: [],
    } as WordListData);
    store
      .dispatch(Actions.GET_BEHAVIORAL_WORDLIST_ITEMS, props.surveyType)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the list of words.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const listChange = () => {
      emit("list-change", wordListData.value);
    };

    watch(wordListData.value, () => {
      listChange();
    });

    watch(words, () => {
      isDataReady.value = true;
    });

    return { isDataReady, words, wordListData, listChange };
  },
});
